'use client';

import 'swiper/css';

import { HiChevronLeft, HiChevronRight, HiStar } from 'react-icons/hi2';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { Locale } from '../../../../../i18n-config';
import { useTranslation } from '@/i18n/client';

type Props = {
  lang: Locale;
};

const Section8 = ({ lang }: Props) => {
  const { t } = useTranslation(lang);
  const [swiper, setSwiper] = useState<any>();

  const testomonials = [
    {
      starts: 5,
      comment:
        'Super User Manager has been a huge help for Lumberjack. Their user management service lets us focus on adding new features without worrying about managing users. We appreciate their support in our growth!',
      name: '',
      occupy: 'CTO of Lumberjack',
    },
    {
      starts: 5,
      comment:
        'Super User Manager has been a game-changer for Louisebot. In the fast-paced world of chatbot development, speed is crucial, and their seamless user management allowed us to focus on innovation and growth. Thanks to their reliable service, we were able to develop and improve Louisebot much faster. Highly recommend!',
      name: '',
      occupy: 'Product Manager of Louisebot',
    },
    {
      starts: 5,
      comment:
        "Thanks to the Super User Manager's service, MyPIL runs effortlessly even with heavy traffic. Their user permissions, role management, and authorization tools ensure our product stays secure and efficient. We truly appreciate their reliable support.",
      name: '',
      occupy: 'Lead Developer of MyPIL',
    },
  ];

  return (
    <section className="sec-8">
      <div className="container mx-auto">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-1 sm:flex hidden items-center justify-start">
            <div
              className="w-12 h-12 relative rounded-[99px] flex flex-col items-center justify-center border border-gray-800 navigation-item"
              onClick={() => swiper?.slidePrev()}
            >
              <HiChevronLeft size={22} color="#9CA3AF" />
            </div>
          </div>
          <div className="sm:col-span-10 col-span-12">
            <Swiper
              modules={[Autoplay]}
              autoplay={{ delay: 5000 }}
              onInit={(ev) => {
                setSwiper(ev);
              }}
              autoHeight={false}
            >
              {testomonials.map((i, el) => {
                return (
                  <SwiperSlide key={el}>
                    <div className="testimonial-item">
                      <div className="stars inline-flex items-center gap-1 mb-8">
                        {[1, 2, 3, 4, 5].map((_, index) => (
                          <HiStar key={index} size={20} className="text-amber-500" />
                        ))}
                        <span>{i.starts}.0</span>
                        <span className="text-gray-400 text-sm">/ 5</span>
                      </div>

                      <p className="text-gray-100 text-xl font-bold font-['Figtree'] leading-8 mb-10">“{i.comment}”</p>

                      <div className="user">
                        {/* <Image
                          alt={i.fullName}
                          src={i.image as string}
                          className="tw-w-10 tw-rounded-full"
                          width={40}
                          height={40}
                        /> */}
                        <div>
                          <span className="text-gray-100 text-base font-semibold font-['Lexend'] leading-normal">
                            {i.name}
                          </span>
                          <span className="text-gray-100 text-base font-semibold font-['Lexend'] leading-normal">
                            {i.occupy}
                          </span>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="col-span-1 sm:flex hidden items-center justify-end">
            <div
              className="w-12 h-12 relative rounded-[99px] flex flex-col items-center justify-center border border-gray-800 navigation-item"
              onClick={() => swiper?.slideNext()}
            >
              <HiChevronRight size={22} color="#9CA3AF" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section8;
