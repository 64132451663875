'use client';

import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { Locale } from '../../../../../i18n-config';
import { useTranslation } from '@/i18n/client';
import { useWindowSize } from '@/hooks/useWindowSize';

type Props = {
  lang: Locale;
};

const Section4 = ({ lang }: Props) => {
  const { t } = useTranslation(lang);
  const [width, height] = useWindowSize();

  const data: Array<{ title: string; description: string; imageSrc: string }> = [
    {
      title: t('Home/V2/Section4/key1'),
      description: t('Home/V2/Section4/key2'),
      imageSrc: 'https://cdn.1cdn.app/application/UMD/24072012094850_Loyalty_Programs.svg',
    },
    {
      title: t('Home/V2/Section4/key3'),
      description: t('Home/V2/Section4/key4'),
      imageSrc: 'https://cdn.1cdn.app/application/UMD/24072012094882_Secure_Authentication.svg',
    },
    {
      title: t('Home/V2/Section4/key5'),
      description: t('Home/V2/Section4/key6'),
      imageSrc: 'https://cdn.1cdn.app/application/UMD/24072012073189_Comprehensive_User_Statistics.svg',
    },
    {
      title: t('Home/V2/Section4/key7'),
      description: t('Home/V2/Section4/key8'),
      imageSrc: 'https://cdn.1cdn.app/application/UMD/24072012094857_User_Database.svg',
    },
    {
      title: t('Home/V2/Section4/key9'),
      description: t('Home/V2/Section4/key10'),
      imageSrc: 'https://cdn.1cdn.app/application/UMD/24072012073128_Detailed_User_Events.svg',
    },
    {
      title: t('Home/V2/Section4/key11'),
      description: t('Home/V2/Section4/key12'),
      imageSrc: 'https://cdn.1cdn.app/application/UMD/24072012094851_Password_Levels.svg',
    },
    {
      title: t('Home/V2/Section4/key13'),
      description: t('Home/V2/Section4/key14'),
      imageSrc: 'https://cdn.1cdn.app/application/UMD/24072012094836_User_Authorization_Management.svg',
    },
    {
      title: t('Home/V2/Section4/key15'),
      description: t('Home/V2/Section4/key16'),
      imageSrc: 'https://cdn.1cdn.app/application/UMD/24072012073163_Group_Management.svg',
    },
    {
      title: t('Home/V2/Section4/key17'),
      description: t('Home/V2/Section4/key18'),
      imageSrc: 'https://cdn.1cdn.app/application/UMD/2407201209480_Personal_User_Profiles.svg',
    },
    {
      title: t('Home/V2/Section4/key19'),
      description: t('Home/V2/Section4/key20'),
      imageSrc: 'https://cdn.1cdn.app/application/UMD/24072012094881_Role-Based_Access_Control.svg',
    },
    {
      title: t('Home/V2/Section4/key21'),
      description: t('Home/V2/Section4/key22'),
      imageSrc: 'https://cdn.1cdn.app/application/UMD/24072012073117_Integrated_Email_Announcements.svg',
    },
    {
      title: t('Home/V2/Section4/key23'),
      description: t('Home/V2/Section4/key24'),
      imageSrc: 'https://cdn.1cdn.app/application/UMD/2407201207313_Full-Featured_API.svg',
    },
  ];

  const [showAll, setShowAll] = useState(true);

  useEffect(() => {
    setShowAll(width <= 1023 ? false : true);
  }, [width]);

  return (
    <section className="sec-4 lg:py-[84px] py-[52px]">
      <div className="container max-w-full relative">
        <div className="md:w-11/12 w-full mx-auto grid grid-cols-12 gap-y-[52px] lg:gap-x-[52px]">
          {(showAll ? data : data.slice(0, 3)).map((item, index) => (
            <div key={index} className="lg:col-span-4 col-span-12">
              <div className="h-72 w-full flex-col lg:justify-start justify-center items-center gap-6 inline-flex">
                <div className="mix-blend-luminosity w-[180px] h-[180px] relative">
                  <Image src={item.imageSrc} alt={item.title} fill />
                </div>
                <div className="self-stretch h-[84px] flex-col justify-start items-start gap-2 flex">
                  <div className="self-stretch text-center text-gray-100 text-xl font-bold font-['Figtree'] leading-7">
                    {item.title}
                  </div>
                  <div className="self-stretch text-center text-gray-400 text-base font-normal font-['Figtree'] leading-normal">
                    {item.description}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <button
          onClick={() => setShowAll(!showAll)}
          className="w-full text-center text-sky-400 text-base font-normal font-['Figtree'] leading-normal lg:hidden mt-10"
        >
          {!showAll ? t('Home/V2/Section4/key25') : t('Home/V2/Section4/key26')}
        </button>
      </div>
    </section>
  );
};

export default Section4;
