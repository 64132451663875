'use client';

import { Locale } from '../../../../../i18n-config';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  lang: Locale;
};

const Section6 = ({ lang }: Props) => {
  const { t } = useTranslation(lang);

  return (
    <section className="sec-6 py-8">
      <div className="container mx-auto">
        <div className="flex-col justify-center items-center gap-4 flex">
          <div className="self-stretch text-center text-gray-100 text-4xl font-bold font-['Figtree'] leading-10">
            {t('Home/V2/Section6/key1')}
          </div>
          <div className="md:w-[768px] w-full text-center text-gray-400 text-xl font-semibold font-['Figtree'] leading-7">
            {t('Home/V2/Section6/key2')}
            <br />
            {t('Home/V2/Section6/key3')}
            <br />
            {t('Home/V2/Section6/key4')}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section6;
