'use client';

import { HiGlobeAlt, HiLightningBolt, HiTerminal } from 'react-icons/hi';
import { Button } from 'flowbite-react';
import Image from 'next/image';
import Link from 'next/link';
import { Locale } from '../../../../../i18n-config';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  lang: Locale;
};

const Section5 = ({ lang }: Props) => {
  const { t } = useTranslation(lang);

  return (
    <section className="sec-5 lg:py-[84px] py-[52px]">
      <div className="container mx-auto">
        <div className="rounded-[32px] md:bg-gray-800">
          <div className="w-full rounded-[32px] grid grid-cols-12 md:bg-gradient-radial to-gray-800 from-[#35181E]">
            <div className="md:col-span-6 col-span-12 lg:p-[67px] py-7 px-2">
              <div className="text-gray-100 text-4xl font-bold font-['Figtree'] leading-10 mb-3">
                {t('Home/V2/Section5/key1')}
              </div>

              <div className=" text-gray-400 text-lg font-semibold font-['Figtree'] leading-7 mb-8">
                {t('Home/V2/Section5/key2')}
              </div>

              <div className="flex-col justify-start items-start gap-8 flex">
                <div className="self-stretch justify-start items-start gap-4 inline-flex">
                  <div className="w-12 h-12 shrink-0 bg-sky-500 rounded-[99px] justify-center items-center flex">
                    <HiGlobeAlt size={22} className="text-gray-900" />
                  </div>
                  <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                    <div className="self-stretch text-gray-100 text-lg font-semibold font-['Figtree'] leading-7">
                      {t('Home/V2/Section5/key3')}
                    </div>
                    <div className="self-stretch text-gray-400 text-sm font-normal font-['Figtree'] leading-tight">
                      {t('Home/V2/Section5/key4')}
                    </div>
                  </div>
                </div>
                <div className="self-stretch justify-start items-start gap-4 inline-flex">
                  <div className="w-12 h-12 shrink-0 bg-sky-500 rounded-[99px] justify-center items-center flex">
                    <HiTerminal size={22} className="text-gray-900" />
                  </div>
                  <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                    <div className="self-stretch text-gray-100 text-lg font-semibold font-['Figtree'] leading-7">
                      {t('Home/V2/Section5/key5')}
                    </div>
                    <div className="text-gray-400 text-sm font-normal font-['Figtree'] leading-tight">
                      {t('Home/V2/Section5/key6')}
                    </div>
                  </div>
                </div>
                <div className="self-stretch justify-start items-start gap-4 inline-flex">
                  <div className="w-12 h-12 shrink-0 bg-sky-500 rounded-[99px] justify-center items-center flex">
                    <HiLightningBolt size={22} className="text-gray-900" />
                  </div>
                  <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                    <div className="self-stretch text-gray-100 text-lg font-semibold font-['Figtree'] leading-7">
                      {t('Home/V2/Section5/key7')}
                    </div>
                    <div className="self-stretch text-gray-400 text-sm font-normal font-['Figtree'] leading-tight">
                      {t('Home/V2/Section5/key8')}
                    </div>
                  </div>
                </div>
              </div>

              <Button color={'light'} as={Link} href={`${lang}/pricing`} className="mt-8 w-max">
                {t('Home/V2/Section5/key9')}
              </Button>
            </div>
            <div className="md:col-span-6 col-span-12 relative md:w-full md:h-full md:py-0 py-4">
              <Image
                src={'https://cdn.1cdn.app/application/UMD/24073009593975_Ready-to-Use_Forms_Without_border.svg'}
                alt="ready to use"
                height={0}
                width={0}
                className="md:w-[90%] w-full md:h-[110%] h-[460px] md:absolute md:top-[-5%] xl:right-0 sm:right-[5%] right-0"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section5;
