/* eslint-disable react/no-unescaped-entities */
'use client';

import { Button } from 'flowbite-react';
import Link from 'next/link';
import { Locale } from '../../../../i18n-config';
import React from 'react';
import { useTranslation } from '@/i18n/client';

type Props = {
  lang: Locale;
  title?: string;
  description?: string | React.ReactNode;
  action?: {
    to: string;
    label: string;
  };
};

const Section5 = ({ lang, title, description, action }: Props) => {
  const { t } = useTranslation(lang);

  return (
    <div
      className="sec-5"
      style={{
        backgroundImage: 'radial-gradient(circle at center 1000px, rgba(53,24,30,1) 0%, rgba(3,7,18,1) 100%)',
      }}
    >
      <div className="container max-w-full py-20">
        <div className="self-stretch flex-col justify-center items-center gap-4 flex">
          <h2 className="self-stretch text-center text-white text-4xl font-bold font-['Figtree'] leading-10">
            {title ?? t('Home/Section5/key1')}
          </h2>

          <p className="self-stretch text-center text-gray-300 text-lg font-normal font-['Figtree'] leading-7 lg:w-[50%] w-full mx-auto mb-8">
            {description ?? t('Home/Section5/key2')}
          </p>

          <Button as={Link} href={action?.to ?? `/${lang}/pricing`} color={'light'}>
            {action?.label ?? t('Home/Section5/key3')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Section5;
