'use client';

import { HiMailOpen, HiPencilAlt } from 'react-icons/hi';
import { Button } from 'flowbite-react';
import Image from 'next/image';
import Link from 'next/link';
import { Locale } from '../../../../../i18n-config';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  lang: Locale;
};

const Section7 = ({ lang }: Props) => {
  const { t } = useTranslation(lang);

  return (
    <section className="sec-7 py-12 my-10">
      <div className="container mx-auto">
        <div className="grid grid-cols-12 md:gap-x-12 gap-y-4 rounded-xl bg-gray-900 bg-opacity-30">
          <div className="md:col-span-6 col-span-12 md:order-1 order-2 relative md:w-full md:h-full">
            <Image
              src={'https://cdn.1cdn.app/application/UMD/24072012073116_All-in-One_Management.svg'}
              width={0}
              height={0}
              alt="All-in-one"
              className="w-full h-[120%] md:absolute top-[-10%] sm:left-8 left-0"
            />
          </div>
          <div className="md:col-span-6 col-span-12 md:order-2 order-1 flex-col justify-start items-start gap-6 flex md:p-[64px] py-7 px-2">
            <div className="self-stretch flex-col justify-start items-start gap-3 flex">
              <div className="self-stretch text-gray-100 text-4xl font-bold font-['Figtree'] leading-10">
                {t('Home/V2/Section7/key1')}
              </div>
              <div className="self-stretch text-gray-400 text-lg font-semibold font-['Figtree'] leading-7">
                {t('Home/V2/Section7/key2')}
              </div>
            </div>
            <div className="self-stretch flex-col justify-start items-start gap-[52px] flex">
              <div className="self-stretch flex-col justify-start items-start gap-[52px] flex">
                <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                  <div className="self-stretch justify-start items-start gap-4 inline-flex">
                    <div className="w-12 h-12 bg-sky-600 rounded-[99px] justify-center items-center flex">
                      <HiPencilAlt size={22} className="text-gray-900" />
                    </div>
                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                      <div className="self-stretch text-gray-100 text-lg font-semibold font-['Figtree'] leading-7">
                        {t('Home/V2/Section7/key3')}
                      </div>
                      <div className="self-stretch text-gray-400 text-sm font-normal font-['Figtree'] leading-tight">
                        {t('Home/V2/Section7/key4')}
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch justify-start items-start gap-4 inline-flex">
                    <div className="w-12 h-12 bg-sky-600 rounded-[99px] justify-center items-center flex">
                      <HiMailOpen size={22} className="text-gray-900" />
                    </div>
                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                      <div className="self-stretch text-gray-100 text-lg font-semibold font-['Figtree'] leading-7">
                        {t('Home/V2/Section7/key5')}
                      </div>
                      <div className="self-stretch text-gray-400 text-sm font-normal font-['Figtree'] leading-tight">
                        {t('Home/V2/Section7/key6')}
                      </div>
                    </div>
                  </div>
                </div>
                <Button as={Link} href={`/${lang}/pricing`} color={'light'}>
                  {t('Home/V2/Section7/key7')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section7;
