'use client';

import { Locale } from '../../../../../i18n-config';
import React from 'react';
import { useTranslation } from '@/i18n/client';

type Props = {
  lang: Locale;
};

const Section3 = ({ lang }: Props) => {
  const { t } = useTranslation(lang);

  return (
    <section className="sec-3 py-[52px]">
      <div className="container max-w-full relative">
        <div className="md:w-11/12 w-full mx-auto grid grid-cols-12 gap-4">
          <div className="lg:col-span-4 col-span-12">
            <div className="text-gray-100 text-4xl font-bold font-['Figtree'] leading-10">
              {t('Home/V2/Section3/key1')}
            </div>
          </div>
          <div className="lg:col-span-2 col-span-6">
            <div className="flex-col justify-start items-start gap-3 inline-flex">
              <div className="text-gray-100 text-3xl font-bold font-['Figtree'] leading-9">
                {t('Home/V2/Section3/key2')}
              </div>
              <div className="self-stretch text-gray-400 text-base font-normal font-['Figtree'] uppercase leading-normal">
                {t('Home/V2/Section3/key3')}
              </div>
            </div>
          </div>
          <div className="lg:col-span-2 col-span-6">
            <div className="flex-col justify-start items-start gap-3 inline-flex">
              <div className="text-gray-100 text-3xl font-bold font-['Figtree'] leading-9">
                {t('Home/V2/Section3/key4')}
              </div>
              <div className="self-stretch text-gray-400 text-base font-normal font-['Figtree'] uppercase leading-normal">
                {t('Home/V2/Section3/key5')}
              </div>
            </div>
          </div>
          <div className="lg:col-span-2 col-span-6">
            <div className="flex-col justify-start items-start gap-3 inline-flex">
              <div className="text-gray-100 text-3xl font-bold font-['Figtree'] leading-9">
                {t('Home/V2/Section3/key6')}
              </div>
              <div className="self-stretch text-gray-400 text-base font-normal font-['Figtree'] uppercase leading-normal">
                {t('Home/V2/Section3/key7')}
              </div>
            </div>
          </div>
          <div className="lg:col-span-2 col-span-6">
            <div className="flex-col justify-start items-start gap-3 inline-flex">
              <div className="text-gray-100 text-3xl font-bold font-['Figtree'] leading-9">
                {t('Home/V2/Section3/key8')}
              </div>
              <div className="self-stretch text-gray-400 text-base font-normal font-['Figtree'] uppercase leading-normal">
                {t('Home/V2/Section3/key9')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section3;
