'use client';

import { Button } from 'flowbite-react';
import Image from 'next/image';
import Link from 'next/link';
import { Locale } from '../../../../../i18n-config';
import React from 'react';
import { useTranslation } from '@/i18n/client';
import { Trans } from 'react-i18next';

type Props = {
  lang: Locale;
};

const Section1 = ({ lang }: Props) => {
  const { t } = useTranslation(lang);

  const textStyle = {
    color: 'transparent',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text', // For older WebKit browsers
    backgroundImage: `linear-gradient(to right, #58B7E2, #CD4F68)`,
  };

  return (
    <section className="sec-1 sm:py-[64px] py-[30px] bg-gradient-to-b from-gray-950 to-[#35181E]">
      <div className="container max-w-full relative">
        <div className="grid grid-cols-12 sm:gap-3 gap-8 items-center">
          <div className="md:col-span-6 col-span-12 lg:pl-10">
            <h1 className="text-white lg:text-6xl text-5xl font-extrabold font-['Figtree'] leading-[60px] mb-6">
              {t('Home/V2/Section1/key1')}{' '}
              <span className="text-6xl font-extrabold font-['Figtree'] leading-[60px]" style={textStyle}>
                {t('Home/V2/Section1/key2')}
              </span>
            </h1>

            <Button as={Link} href={`/${lang}/pricing`} color={'light'} className="w-max mb-8">
              {t('Home/V2/Section1/key4')}
            </Button>

            <div className="lg:mx-auto w-full text-gray-400 text-lg font-normal font-['Figtree'] leading-7">
              <Trans
                i18nKey={'Home/V2/Section1/key3'}
                values={{ free: t('Home/V2/Section1/key5') }}
                components={{ b: <b></b> }}
              />
            </div>
          </div>
          <div className="md:col-span-6 col-span-12">
            <div className="relative">
              <Image
                src={'https://cdn.1cdn.app/application/UMD/24073010344927_Hero.png'}
                width={0}
                height={500}
                alt=""
                sizes="100vw"
                className="w-full object-cover object-top"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section1;
