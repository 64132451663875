'use client';

import { HiMailOpen, HiPencilAlt } from 'react-icons/hi';
import { Button } from 'flowbite-react';
import { HiStar } from 'react-icons/hi2';
import Image from 'next/image';
import Link from 'next/link';
import { Locale } from '../../../../../i18n-config';
import React from 'react';
import { useTranslation } from '@/i18n/client';

type Props = {
  lang: Locale;
};

const Section2 = ({ lang }: Props) => {
  const { t } = useTranslation(lang);

  return (
    <section className="sec-2 min-h-[768px] sm:pt-[44px] pt-[30px]">
      <div className="container max-w-full">
        <center className="mb-8">
          <h2 className="text-white lg:text-6xl text-4xl font-extrabold font-['Figtree'] leading-[60px]">
            {t('Home/V2/Section2/key1')}
          </h2>
        </center>

        <div className="md:w-11/12 w-full mx-auto grid grid-cols-12 gap-3">
          <div className="md:col-span-6 col-span-12 p-8 bg-gray-900 rounded-[20px] flex flex-col">
            <div className="md:min-h-[220px] mb-4">
              {/* Title */}
              <div className="text-gray-100 text-2xl font-bold font-['Figtree'] md:leading-loose leading-none mb-3 flex items-center flex-wrap gap-2">
                <span>{t('Home/V2/Section2/key2')} </span>
                <div>
                  {[1, 2, 3, 4, 5].map((item, index) => (
                    <HiStar key={index} className="text-yellow-300 inline-flex" size={18} />
                  ))}
                </div>
              </div>
              {/* description */}
              <div className={`text-gray-100 text-base font-normal font-['Figtree'] leading-normal mb-6`}>
                {t('Home/V2/Section2/key3')}
              </div>
              {/* actions */}
              <div className="flex items-center flex-wrap gap-4">
                <Button color={'light'} as={Link} href={`/${lang}/pricing`} className="md:w-max w-full">
                  {t('Home/V2/Section2/key4')}
                </Button>
                <Button color={'dark'} as={Link} href={`/${lang}/loyalty-programs`} className="md:w-max w-full">
                  {t('Home/V2/Section2/key5')}
                </Button>
              </div>
            </div>

            {/* Image */}
            <div className="w-full md:h-[467px] h-[300px]  mt-auto relative">
              <Image
                src="https://cdn.1cdn.app/application/UMD/24072012094882_Loyalty_Program.svg"
                alt="Loyalty Program"
                fill
                className="grayscale"
              />
            </div>
          </div>

          <div className="md:col-span-6 col-span-12 p-8 bg-gray-900 rounded-[20px] flex flex-col">
            <div className="md:min-h-[220px] mb-4">
              {/* Title */}
              <div className="text-gray-100 text-2xl font-bold font-['Figtree'] md:leading-loose leading-none mb-3">
                {t('Home/V2/Section2/key6')}
              </div>
              {/* description */}
              <div className="text-gray-100 text-base font-normal font-['Figtree'] leading-normal mb-6">
                {t('Home/V2/Section2/key7')}
              </div>
              {/* actions */}
              <div className="flex items-center gap-4">
                <Button color={'light'} as={Link} href={`/${lang}/pricing`} className="md:w-max w-full">
                  {t('Home/V2/Section2/key8')}
                </Button>
              </div>
            </div>

            {/* Image */}
            <div className="w-full md:h-[467px] h-[300px]  mt-auto relative">
              <Image
                src="https://cdn.1cdn.app/application/UMD/24072012073182_Centralized_User_Management.svg"
                alt="Loyalty Program"
                fill
                className="grayscale"
              />
            </div>
          </div>

          <div className="col-span-12 md:grid md:grid-cols-12 flex flex-col md:w-auto gap-8 md:min-h-[531px] p-8 bg-gray-900 rounded-[20px]">
            <div className="md:col-span-6 col-span-12 md:order-1 order-2">
              <div className="w-full md:h-[467px] h-[300px] md:mt-0 mt-6 relative">
                <Image
                  src="https://cdn.1cdn.app/application/UMD/24072012073125_Integrated_Email_Notifications.svg"
                  alt="Loyalty Program"
                  fill
                  className="grayscale"
                />
              </div>
            </div>
            <div className="md:col-span-6 col-span-12 md:order-2 order-1">
              {/* Title */}
              <div className="text-gray-100 text-2xl font-bold font-['Figtree'] md:leading-loose leading-none mb-3">
                {t('Home/V2/Section2/key9')}
              </div>
              {/* description */}
              <div className="text-gray-100 text-base font-normal font-['Figtree'] leading-normal mb-6">
                {t('Home/V2/Section2/key10')}
              </div>
              {/* actions */}
              <div className="flex items-center gap-4 mb-14">
                <Button color={'light'} as={Link} href={`/${lang}/pricing`} className="md:w-max w-full">
                  {t('Home/V2/Section2/key11')}
                </Button>
              </div>

              {/* other */}
              <div className="flex-col justify-start items-start gap-8 inline-flex">
                <div className="self-stretch justify-start items-start gap-4 inline-flex">
                  <div className="w-12 h-12 bg-sky-600 rounded-[99px] justify-center items-center flex">
                    <div className="w-6 h-6 relative flex flex-col justify-center items-center">
                      <HiMailOpen size={30} className="text-gray-900" />
                    </div>
                  </div>
                  <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                    <div className="self-stretch text-gray-100 text-lg font-semibold font-['Figtree'] leading-7">
                      {t('Home/V2/Section2/key12')}
                    </div>
                    <div className="self-stretch text-gray-400 text-sm font-normal font-['Figtree'] leading-tight">
                      {t('Home/V2/Section2/key13')}
                    </div>
                  </div>
                </div>
                <div className="self-stretch justify-start items-start gap-4 inline-flex">
                  <div className="w-12 h-12 bg-sky-600 rounded-[99px] justify-center items-center flex">
                    <div className="w-6 h-6 relative flex flex-col justify-center items-center">
                      <HiPencilAlt size={30} className="text-gray-900" />
                    </div>
                  </div>
                  <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                    <div className="self-stretch text-gray-100 text-lg font-semibold font-['Figtree'] leading-7">
                      {t('Home/V2/Section2/key14')}
                    </div>
                    <div className="self-stretch text-gray-400 text-sm font-normal font-['Figtree'] leading-tight">
                      {t('Home/V2/Section2/key15')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;
